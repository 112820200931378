import React from "react"
import {
  Box,
  Flex,
  Grid,
  Heading,
  Text,
  Container,
  Image,
} from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { number } from "prop-types"
import * as Scroll from "react-scroll"
import ArrowRight from "../../images/arrow-right.svg"
const LinkScroll = Scroll.Link

const LinkBanner = ({ title, subtitle, linkText }) => {
  return (
    <Box sx={{ 
      py:[4],
      backgroundColor:"primary",
      borderRadius: "0px 0px 80px 80px",
    }}>
      <Container>
        <Grid
          columns={[1, 2]}
          gap={[3,0]}
          sx={{ justifyContent: "space-between" }}
        >
          <Box>
            <Heading as="h2" sx={{ color: "light", fontSize:[2] }}>
              {title}
            </Heading>
            <Text as="p" sx={{ color: "light", fontWeight:"300", opacity:".6" }}>{subtitle} </Text>
          </Box>
          <Flex
            sx={{
              color: "light",
              width: "100%",
              textTransform: "uppercase",
              justifyContent: ["center","end"],
            }}
          >
            <Box sx={{ alignSelf: "center",  }}>
              <LinkScroll
                spy={false}
                smooth={true}
                duration={500}
                to={`contact-form`}
                href="#"
              >
                <Flex
                  sx={{
                    py: [3], px: [4],
                    fontSize: [0],
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "dark"
                  }}
                >
                  {linkText}
                  <Image sx={{ ml: [2] }} src={ArrowRight} />
                </Flex>
              </LinkScroll>
            </Box>
          </Flex>
        </Grid>
      </Container>
    </Box>
  )
}

export default LinkBanner
