import React from "react"
import { graphql } from "gatsby"
import { Container, Heading, Box } from "@theme-ui/components"
import Layout from "../components/layout"
import { getHomePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import LatestArticles from "../components/latestArticles"
import { useFavicon } from "../hooks/useFavicon"

import BigImageWithTextAndList from "../components/blocks/bigImageWithTextAndList"
import DoubleColumnImageAndText from "../components/blocks/doubleColumnImageAndText"
import TitleAndBody from "../components/blocks/titleAndBody"
import ImageBgAndText from "../components/blocks/imageBgAndText"
import ImageAndText from "../components/blocks/imageAndText"
import NumbersCollection from "../components/blocks/numbersCollections"
import ContactForm from "../components/blocks/contactFrom"
import LinkBanner from "../components/blocks/linkBanner"
import Embed from "../components/blocks/embed"

import { getColor } from "@theme-ui/color"
import themeUiTheme from "../gatsby-plugin-theme-ui"

const Home = ({ data: { page, site, articles } }) => {
  const primary = getColor(themeUiTheme, "primary")
  const secondary = getColor(themeUiTheme, "secondary")

  const favicon = useFavicon().site.faviconMetaTags

  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getHomePath(locale),
    }
  })

  const blockIds = []

  page.content.map(block => {
    if (block.menuText)
      blockIds.push({
        id: block.id,
        title: block.menuText,
      })
  })

  return (
    <Layout locale={page.locale} pageSections={blockIds} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      {page.content.map(block => (
        <>
          {block.model.apiKey === "big_image_with_text_and_list" && (
            <Box
              as="section"
              key={block.id}
              id={block.id}
              sx={{ backgroundColor: "primary" }}
            >
              <BigImageWithTextAndList
                kicker={block.kicker}
                image={block.image}
                video={block.video}
                title={block.title}
                description={block.description}
              />
            </Box>
          )}
          {block.model.apiKey === "title_and_body" && (
            <Box
              as="section"
              key={block.id}
              id={block.id}
              sx={{ backgroundColor: "dark" }}
            >
              <TitleAndBody
                subtitle={block.content.subtitle}
                title={block.content.title}
                body={block.content.body}
              />
            </Box>
          )}
          {block.model.apiKey === "link_banner" && (
            <Box
              as="section"
              key={block.id}
              id={block.id}
              sx={{ backgroundColor: `light` }}
            >
              <LinkBanner
                title={block.title}
                subtitle={block.subtitle}
                linkText={block.linkText}
              />
            </Box>
          )}
          {block.model.apiKey === "embed" && (
            <Box
              as="section"
              key={block.id}
              id={block.id}
              sx={{ backgroundColor: "light" }}
            >
              <Embed
                title={block.title}
                code={block.code}
                fullWidth={block.fullWidth}
              />
            </Box>
          )}
          {block.model.apiKey === "double_column_image_and_text" && (
            <Box
              as="section"
              key={block.id}
              id={block.id}
              sx={{ backgroundColor: "light" }}
            >
              <DoubleColumnImageAndText
                contents={block.contents}
                links={block.links}
              />
            </Box>
          )}
          {block.model.apiKey === "image_and_text" && (
            <Box
              as="section"
              key={block.id}
              id={block.id}
              sx={{ backgroundColor: "dark" }}
            >
              <ImageAndText
                label={block.content.label}
                subtitle={block.content.subtitle}
                title={block.content.title}
                body={block.content.body}
                image={block.image}
              />
            </Box>
          )}
          {block.model.apiKey === "image_bg_and_text" && (
            <Box
              as="section"
              key={block.id}
              id={block.id}
              sx={{
                background: `linear-gradient(90deg, ${primary} 100%, #FFF 50% )`,
              }}
            >
              <ImageBgAndText
                link={block.link}
                label={block.content.label}
                subtitle={block.content.subtitle}
                title={block.content.title}
                body={block.content.body}
                image={block.image}
              />
            </Box>
          )}
          {block.model.apiKey === "numbers_collection" && (
            <Box id={block.id} as="section" key={block.id}>
              <NumbersCollection title={block.title} numbers={block.numbers} />
            </Box>
          )}
          {block.model.apiKey === "contact_form" && (
            <Box as="section" key={block.id} id={block.id}>
              <Box id="contact-form">
                <ContactForm
                  title={block.title}
                  subtitle={block.kicker}
                  description={block.subtitle}
                />
              </Box>
            </Box>
          )}
        </>
      ))}
    </Layout>
  )
}

export default Home

export const query = graphql`
  query HomeQuery($id: String!, $locale: String!) {
    page: datoCmsHomePage(id: { eq: $id }) {
      id
      title
      locale
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content {
        ... on DatoCmsBigImageWithTextAndList {
          id
          menuText
          kicker
          title
          description
          video{
            url
          }
          image {
            gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsLinkBanner {
          id
          menuText
          title
          subtitle
          linkText
          model {
            apiKey
          }
        }
        ... on DatoCmsDoubleColumnImageAndText {
          id
          menuText
          contents: content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          links {
            title
            documents {
              
              url
              title
              format
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsEmbed {
          id
          ...EmbedDetails
        }
        ... on DatoCmsTitleAndBody {
          id
          menuText
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageBgAndText {
          id
          menuText
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          link {
            ... on DatoCmsExternalLink {
              id
              anchor
              url
              model {
                apiKey
              }
            }
          }
          image {
            gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageAndText {
          id
          menuText
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          image {
            gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsNumbersCollection {
          id
          menuText
          title
          imageNumber: image {
            gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
          }
          numbers {
            legend
            float
            suffix
            prefix
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContactForm {
          id
          menuText
          title
          kicker
          subtitle
          privacyPolicyDescription
          newsletterDescription
          model {
            apiKey
          }
        }
      }
    }
    articles: allDatoCmsArticle(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      limit: 6
    ) {
      nodes {
        ...ArticleDetails
        ...ArticleAllSlugLocales
        ...ArticleMeta
      }
    }
    site: datoCmsSite {
      locales
    }
  }

  fragment RichContent on DatoCmsRichContent {
    title
    label
    subtitle
    image {
      url
      gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
    }
    body {
      value
      blocks {
        __typename
        ... on DatoCmsImageGallery {
          id: originalId
          ...ImageGallery
        }
        ... on DatoCmsEmbed {
          id: originalId
          ...EmbedDetails
        }
        ... on DatoCmsNumbersGroup {
          id: originalId
          numbers {
            legend
            float
            suffix
            prefix
          }
        }
      }
      links {
        __typename
        ... on DatoCmsInternalLink {
          id: originalId
          anchor
          locale
          model {
            apiKey
          }
        }
      }
      value
    }

    model {
      apiKey
    }
  }
`
