import React, { useEffect, useState } from "react"
import { Box, Text, Heading, Flex, Container, Grid } from "@theme-ui/components"
import CountUp from "react-countup"
import { useInView } from "react-intersection-observer"
import { GatsbyImage } from "gatsby-plugin-image"

const NumbersCollection = ({ title, image, numbers }) => {
  return (
    <Box sx={{
      pt:[3],
      pb:[6],
      backgroundColor:"primary"
    }}>
      <Container>
        <Heading
          as="h2"
          variant="h2"
          sx={{ fontSize: [6, 7], color: "light", textAlign: "center" }}
        >
          {title}
        </Heading>
        {image && <GatsbyImage image={image.gatsbyImageData} />}
        <Grid  columns={["1fr","1fr 1fr 1fr"]} sx={{ justifyContent: "center",py:[3,6], }}>
          {numbers.map(number => (
            <Number number={number} />
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

const Number = ({ number }) => {
  const [startCount, setStartCount] = useState(false)
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView === true && startCount === false) {
      setStartCount(true)
    }
  }, [inView])

  return (
    <Flex sx={{ flexDirection:"column",  alignItems:"center"}}>
      <Box ref={ref} sx={{ mr: [2],  }}>
        <Heading sx={{ fontSize: [7,9],  my:[3,0],opacity:.3, color:"light", marginBottom: 0 }}>
          <CountUp
            end={number.float}
            prefix={number.prefix ? `${number.prefix} ` : null}
            start={startCount}
          />
        </Heading>
      </Box>
      <Box>
        <Text
          sx={{
            fontWeight: "300",
            fontSize: [3],
            lineHeight: "22px",
            color: "light",
          }}
        >
          {number.legend}
        </Text>
      </Box>
    </Flex>
  )
}

export default NumbersCollection
