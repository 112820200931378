import React from "react"
import {
  Box,
  Flex,
  Grid,
  Heading,
  Text,
  Container,
  Image,
} from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { number } from "prop-types"
import { OutboundLink } from "../link"
import ArrowDownLong from "../../images/arrow-down-long.svg"
import FadeInWhenVisible from "../fadeInWhenVisible"

const DoubleColumnImageAndText = ({ contents, links }) => {
  {console.log(links)}
  return (
    <Box
      sx={{
        py: [3, 3, 3, 6],

        backgroundColor: "dark",
        borderRadius: "0px 80px 0px 80px",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Container>
        <Grid columns={[1, 2]} gap={[0]}>
          {contents.map((item, index) => (
            <Box sx={{ position: "relative" }}>
              <FadeInWhenVisible>
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: 1,
                    top: ["50%", "25%"],
                    left: index === 1 && ["unset", 7],
                    right: index === 1 && [0, "unset"],
                    width: index === 0 ? "70%" : "80%",
                    transform: ["translateY(-50%)", "translateY(-25%)"],
                    p: {
                      lineHeight: "47px",
                      fontSize: [2, 3],
                      color: "light",
                      fontWeight: "100!important",
                      strong: {
                        fontSize: [3, 5],
                      },
                    },
                  }}
                >
                  <RichContentStructuredText text={item.body} />
                </Box>

                <Box
                  sx={{
                    position: "relative",
                    zIndex: 0,
                    textAlign: index === 0 ? "right" : "left",
                    my: index === 1 && [0, 6],
                    ".gatsby-image-wrapper": {
                      height: "90%",
                      width: "90%",
                      opacity: ".4",
                      borderRadius:
                        index === 0
                          ? "0px 80px 0px 80px"
                          : ["0px 80px 0px 80px", "0px 0px 80px 0px"],
                    },
                  }}
                >
                  <GatsbyImage image={item.image.gatsbyImageData} alt="" />
                </Box>
              </FadeInWhenVisible>
            </Box>
          ))}
        </Grid>
        <Flex
          sx={{
            m: "0 auto",
            color: "light",
            flexWrap:"wrap",
            justifyContent: "center",
            mt: [4],
          }}
        >
          {links.map((link) => (
            <OutboundLink
              href={link.documents[0].url}
              target="_blank"
              sx={{
                my: [2,0],
                alignSelf: "center",
                "&:hover > div": {
                  backgroundColor: "primary",
                },
              }}
            >
              
              <Flex
                sx={{
                  py: [3],
                  px: [4],
                  fontSize: [0],
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {link.title}
                <Image sx={{ ml: [2] }} src={ArrowDownLong} />
              </Flex>
            </OutboundLink>
          ))}
        </Flex>
      </Container>
    </Box>
  )
}

export default DoubleColumnImageAndText
