import React from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { number } from "prop-types"
import FadeInWhenVisible from "../fadeInWhenVisible"

const ImageAndText = ({
  title,
  body,
  image,
  label,
  subtitle,
  rightAligned,
}) => {
  console.log("subtitle", subtitle)
  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "light",
        py: [4, 7],
        pb: [4, 4],
      }}
    >
      <Container>
        <Grid
          columns={[1, 1, ".6fr .4fr"]}
          gap={[0, 0, 32]}
          sx={{ position: "relative" }}
        >
          <FadeInWhenVisible>
            <Flex
              sx={{
                flexDirection: "column",
                py: [4],
              }}
            >
              <Box>
                <Heading
                  as="h2"
                  variant="h2"
                  sx={{ fontSize: [5, 7], my: [0, 0], color: "primary" }}
                >
                  {title}
                </Heading>
              </Box>
              <Box>
                <Text as="h3" sx={{ my: [0], fontSize: [3, 4], color: "dark" }}>
                  {subtitle}
                </Text>
              </Box>
              <Box
                sx={{
                  color: "dark",
                  p: { fontWeight: "300", fontSize: [1] },
                  opacity: ".8",
                  margin: "0 auto",
                }}
              >
                <RichContentStructuredText text={body} theme="light" />
              </Box>
            </Flex>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <Box
              sx={{
                position: ["relative", "relative", "relative"],
                width: ["100%", "100%", "100%"],
                height: "100%",
                ".gatsby-image-wrapper": {
                  height: "100%",
                },
              }}
            >
              <GatsbyImage image={image.gatsbyImageData} alt="" />
            </Box>
          </FadeInWhenVisible>
        </Grid>
      </Container>
    </Box>
  )
}

export default ImageAndText
