import React from "react"
import { Box, Grid, Heading, Container, Text } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import FadeInWhenVisible from "../fadeInWhenVisible"

const TitleAndBody = ({ title, subtitle, body }) => {
  return (
    <Box sx={{ backgroundColor: "light" }}>
      <Container sx={{ py: [6, 8] }}>
        <FadeInWhenVisible>
          <Grid
            columns={[1, 1, "1fr"]}
            gap={[0, 2]}
            sx={{ textAlign: "center" }}
          >
            <Box>
              <Heading
                as="h2"
                variant="h2"
                sx={{ fontSize: [5, 7], my: [0, 0], color: "dark" }}
              >
                {title}
              </Heading>
            </Box>
            <Box>
              <Text
                as="h3"
                sx={{ my: [0], fontSize: [3, 4], color: "primary" }}
              >
                {subtitle}
              </Text>
            </Box>
            <Box
              sx={{
                width: ["100%", "70%", "50%"],
                color: "dark",
                p: { fontWeight: "300", fontSize: [1] },
                opacity: ".8",
                margin: "0 auto",
              }}
            >
              <RichContentStructuredText text={body} />
            </Box>
          </Grid>
        </FadeInWhenVisible>
      </Container>
    </Box>
  )
}

export default TitleAndBody
