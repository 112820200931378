import React, { useState } from "react"
import {
  Box,
  Container,
  Text,
  Heading,
  Grid,
  Input,
  Button,
  Flex,
  Label,
  Image,
  Link,
} from "@theme-ui/components"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui"
import { Textarea, Checkbox } from "theme-ui"
import axios from "axios"
import { InboundLink, OutboundLink } from "../link"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import ArrowRight from "../../images/arrow-right.svg"

const ContactForm = ({ title, subtitle, description }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const dark = getColor(themeUiTheme, "dark")
  const isBrowser = typeof window !== "undefined"

  const [formData, setFormData] = useState({
    email: "",
    nomeCognome: "",
    ragioneSociale: "",
    telefono: "",
    messaggio: "",
    accordoPrivacyCookies: false,
    accordoNewsLetter: false,
    urlPagina: isBrowser && window.location.href,
  })

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const sendMail = async event => {
    event.preventDefault()

    const result = await executeRecaptcha("dynamicAction")

    setLoading(true)
    const data = formData

    if (result)
      axios
        .post("/.netlify/functions/sendMail", data)
        .then(function (response) {
          setSuccess(true)
          setLoading(false)

          if (typeof window !== "undefined" && window.dataLayer !== undefined) {

            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
                'event': 'formSubmission',
                'formType': 'Contact',
            });
          }
        })
        .catch(function (error) {
          setLoading(false)
        })
    else setLoading(false)
  }

  return (
    <Box
      sx={{
        py: [3, 3, 4, 6],
        backgroundColor: "light",
      }}
    >
      <Container name="contactForm">
        <Box>
          <Grid columns={["1fr", "1fr", "1fr", ".5fr .5fr"]} gap={[1, 1, 1, 6]}>
            <Flex sx={{ flexDirection: "column", justifyContent: "start" }}>
              <Heading
                as="h2"
                variant="h1"
                sx={{ fontSize: [6, 7], color: "primary" }}
              >
                {title}
              </Heading>
              <Heading
                as="h3"
                variant="h3"
                sx={{ my: [0], fontSize: [4], color: "dark" }}
              >
                {subtitle}
              </Heading>
              <Text
                as="p"
                variant="sectionTitle"
                sx={{
                  my: [1, 2],
                  color: "dark",
                  fontSize: [4, 5],
                  fontWeight: 300,
                  lineHeight: "36px",
                }}
              >
                {description}
              </Text>
            </Flex>
            {!success ? (
              <Box as="form" onSubmit={sendMail} sx={{ mb: [4] }}>
                <Box>
                  <Grid
                    sx={{
                      flexWrap: "wrap",
                      flexDirection: "column",
                    }}
                    gap={[0, 0, 0, 0]}
                  >
                    <Flex sx={{ flexWrap: "wrap" }}>
                      <Box
                        sx={{
                          pr: [0, 1, 1, 1],
                          pb: [3, 3, 3, 3],
                          width: ["100%", "50%"],
                        }}
                      >
                        <Input
                          onChange={e =>
                            setFormData(prevState => ({
                              ...prevState,
                              nomeCognome: e.target.value,
                            }))
                          }
                          name="nome cognome"
                          placeholder="Nome e Cognome*"
                          variant={"inputs.primary"}
                          required
                        />
                      </Box>
                      <Box
                        sx={{
                          pl: [0, 1, 1, 1],
                          pb: [3, 3, 3, 3],
                          width: ["100%", "50%"],
                        }}
                      >
                        <Input
                          onChange={e =>
                            setFormData(prevState => ({
                              ...prevState,
                              ragioneSociale: e.target.value,
                            }))
                          }
                          name="ragione sociale"
                          placeholder="Ragione Sociale*"
                          variant={"inputs.primary"}
                          required
                        />
                      </Box>
                    </Flex>
                    <Flex sx={{ flexWrap: "wrap" }}>
                      <Box
                        sx={{
                          pr: [0, 1, 1, 1],
                          pb: [3, 3, 3, 3],
                          width: ["100%", "50%"],
                        }}
                      >
                        <Input
                          onChange={e =>
                            setFormData(prevState => ({
                              ...prevState,
                              email: e.target.value,
                            }))
                          }
                          name="email"
                          type="email"
                          placeholder="Email*"
                          variant={"inputs.primary"}
                          required
                        />
                      </Box>
                      <Box
                        sx={{
                          pl: [0, 1, 1, 1],
                          pb: [3, 3, 3, 3],
                          width: ["100%", "50%"],
                        }}
                      >
                        <Input
                          onChange={e =>
                            setFormData(prevState => ({
                              ...prevState,
                              telefono: e.target.value,
                            }))
                          }
                          name="telefono"
                          type="text"
                          placeholder="Telefono*"
                          variant={"inputs.primary"}
                          required
                        />
                      </Box>
                    </Flex>

                    <Textarea
                      sx={{
                        fontFamily: "body",
                        fontSize: [2],
                      }}
                      onChange={e =>
                        setFormData(prevState => ({
                          ...prevState,
                          messaggio: e.target.value,
                        }))
                      }
                      name="messaggio"
                      placeholder="Messaggio"
                      variant={"inputs.primary"}
                      type="textarea"
                    />
                    <LabeledCheckbox
                      checkedCheckbox={e => {
                        setFormData(prevState => ({
                          ...prevState,
                          accordoPrivacyCookies: e,
                        }))
                      }}
                      required={true}
                      contactPage={false}
                    >
                      <Box>
                        <Text
                          variant="p"
                          sx={{
                            fontSize: [0],
                            color: "dark",
                          }}
                        >
                          * Acconsento a che i miei dati personali vengano
                          utilizzati in accordo con la
                          <OutboundLink
                            sx={{
                              color: "dark",
                              textDecoration: "underline",
                            }}
                            href="http://www.mecmatica.it/privacy/"
                            target="_blank"
                          >
                            {" "}
                            privacy
                          </OutboundLink>{" "}
                          e{" "}
                          <OutboundLink
                            sx={{
                              color: "dark",
                              textDecoration: "underline",
                            }}
                            href="http://www.mecmatica.it/cookie-policy"
                            target="_blank"
                          >
                            cookie
                          </OutboundLink>{" "}
                          policy
                        </Text>
                      </Box>
                    </LabeledCheckbox>
                    {console.log(
                      "accordoNewsLetter",
                      formData.accordoNewsLetter
                    )}
                    <LabeledCheckbox
                      checkedCheckbox={e =>
                        setFormData(prevState => ({
                          ...prevState,
                          accordoNewsLetter: e,
                        }))
                      }
                      contactPage={false}
                    >
                      <Box>
                        <Text variant="p" sx={{ color: "dark", fontSize: [0] }}>
                          Acconsento all’uso dei miei dati personali per essere
                          aggiornato sui nuovi arrivi, prodotti in esclusiva e
                          per le finalità di marketing diretto correlate ai
                          servizi offerti e ricevere proposte in linea con i
                          miei interessi attraverso l’analisi dei miei
                          precedenti acquisti
                        </Text>
                      </Box>
                    </LabeledCheckbox>
                    {!success ? (
                      <Box>
                        <Button
                          type="submit"
                          title={
                            !formData.accordoPrivacyCookies
                              ? "Acconsenti al trattamenti dei dati personali"
                              : ""
                          }
                          sx={{
                            mt: [3, 3, 3, 3],
                            borderRadius:"0px",
                            opacity: !formData.accordoPrivacyCookies && 0.7,
                            "&:hover": {
                              color: "light",
                              svg: {
                                circle: {
                                  stroke: "light",
                                  strokeWidth: "2px",
                                },
                                use: {
                                  fill: "light",
                                },
                                "g#Group": {
                                  fill: "light",
                                },
                              },
                            },
                          }}
                          variant={"buttons.full.primaryForm"}
                        >
                          <Flex
                            sx={{
                              color: "light",
                              width: "100%",
                              textTransform: "uppercase",
                              justifyContent: "end",
                            }}
                          >
                            <Box sx={{ alignSelf: "center" }}>
                              <Flex
                                sx={{
                                  py: [3],
                                  px: [4],
                                  fontSize: [0],
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "primary",
                                }}
                              >
                                richiedi informazioni
                                <Image sx={{ ml: [2] }} src={ArrowRight} />
                              </Flex>
                            </Box>
                          </Flex>
                        </Button>
                      </Box>
                    ) : (
                      <Box></Box>
                    )}
                  </Grid>
                </Box>
              </Box>
            ) : (
              <Flex>
                <Heading sx={{ my: [4], color: "primary" }} as="h4">
                  Richiesta inviata!
                </Heading>
              </Flex>
            )}
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

const LabeledCheckbox = ({
  children,
  defaultChecked,
  contactPage,
  checkedCheckbox,
  ...props
}) => {
  const [checked, setChecked] = useState(defaultChecked)

  return (
    <Label
      sx={{
        display: "flex",
        alignItems: "center",
        fontWeight: checked ? "bold" : "normal",
        color: "dark",
        "input:checked~.css-kydphz": {
          color: "light",
        },
        svg: {
          color: "dark",
        },
        mt: [3, 3, 3, 3],
      }}
    >
      <Checkbox
        sx={{
          color: "dark",
          "input:checked~&": {
            color: "dark",
          },
        }}
        checked={checked}
        onChange={() => {
          checkedCheckbox(!checked)
          setChecked(!checked)
        }}
        {...props}
      />
      {children}
    </Label>
  )
}

export default ContactForm
