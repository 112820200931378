import React from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { number } from "prop-types"
import { OutboundLink } from "../link"
import FadeInWhenVisible from "../fadeInWhenVisible"

const BigImageWithTextAndList = ({
  title,
  description,
  image,
  video,
  label,
  kicker,
}) => {
  return (
    <Box sx={{}}>
      <Box
        sx={{
          position: "relative",
          backgroundColor: "black",
          overflow: "hidden",
          minHeight: "100vh",
          borderRadius: "0px 0px 0px 80px",
        }}
      >
        {video && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 0,
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              "&:before": {
                content: "''",
                position: "absolute",
                backgroundColor: "dark",
                opacity: 0.5,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              },
            }}
          >
            <video
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
              title={"background-video"}
              id=""
              class=""
              autoplay="autoplay"
              muted="muted"
              loop="loop"
              playsinline="playsinline"
            >
              <source src={video.url} type="video/mp4" />
            </video>
          </Box>
        )}
        <Container
          sx={{
            pt: [5, 10],
            pb: [6, 6],
            minHeight: "100vh",
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          {image && (
            <Box
              sx={{
                pb: [4, 6],
                textAlign: "center",
                width: ["100%", "80%"],
                height: ["100%", "60%"],
                margin: "0 auto",
                position: "relative",
                ".gatsby-image-wrapper": {
                  width: "100%",
                  height: "100%",
                },
              }}
            >
              <GatsbyImage image={image.gatsbyImageData} alt="" />
              <Box
                sx={{
                  position: "absolute",
                  top: "45%",
                  left: "50%",
                  m: [0],
                  transform: "translate(-50%,-45%)",
                  h1: {
                    color: "light",
                    fontWeight: "300",
                    fontSize: [5, 6],
                    strong: {
                      fontWeight: "700",
                      fontSize: [5, 8],
                    },
                    m: 0,
                  },
                }}
              >
                <Heading
                  as="div"
                  dangerouslySetInnerHTML={{ __html: kicker }}
                />
              </Box>
            </Box>
          )}

          <Box
            sx={{
              position: "absolute",
              top: ["10","35%"],
              left: "50%",
              m: [0],
              width:"100%",
              textAlign:"center",
              transform: "translate(-50%,-45%)",
              h1: {
                color: "light",
                fontWeight: "300",
                fontSize: [6, 6],
                strong: {
                  fontWeight: "700",
                  fontSize: [7, 8],
                },
                m: 0,
              },
            }}
          >
            <Heading as="div" dangerouslySetInnerHTML={{ __html: kicker }} />
          </Box>
          <Grid
            columns={[1, "1fr 1fr"]}
            gap={[2, 7]}
            sx={{ position: "relative", bottom: 0, zIndex: 1 }}
          >
            <FadeInWhenVisible>
              <Box
                sx={{
                  my: [2, 5, 5, 5],
                }}
              >
                <Heading
                  as="div"
                  variant="sectionTitleBlack"
                  sx={{
                    my: [0, 2, 2, 2],
                    color: "light",
                    fontWeight: "body",
                    letterSpacing: "-.5px",
                    lineHeight: "1.12",
                    p: {
                      fontSize: [2, 3],
                    },
                    em: {
                      fontStyle: "normal",
                      color: "primary",
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              </Box>
            </FadeInWhenVisible>
            <FadeInWhenVisible>
              <Box sx={{ py: [2, 2, 2, 5] }}>
                <Box>
                  <Text
                    sx={{
                      display: "block",
                      py: 2,
                      ul: {
                        li: { color: "light" },
                      },
                    }}
                    variant="sectionTitleBlack"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </Box>
              </Box>
            </FadeInWhenVisible>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default BigImageWithTextAndList
