import React from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { number } from "prop-types"
import { OutboundLink } from "../link"
import FadeInWhenVisible from "../fadeInWhenVisible"

const ImageBgAndText = ({
  title,
  body,
  image,
  label,
  subtitle,
  rightAligned,
  link,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: "dark",
        borderRadius: "0px 80px 0px 80px",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: ["relative"],
          zIndex: 0,
          width: "100%",
          height: "100%",
          ".gatsby-image-wrapper": {
            position: "absolute",
            top: 0,
            height: "100%",
            width: "100%",
            opacity: ".4",
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            zIndex: 1,
          }}
        >
          <Container>
            <Flex
              sx={{
                flexDirection: "column",
                px: [0, 8],
                py: [7, 10],
              }}
            >
              <FadeInWhenVisible>
                <Box>
                  <Heading
                    as="h2"
                    variant="h2"
                    sx={{ fontSize: [6, 7], color: "primary" }}
                  >
                    {title}
                  </Heading>
                </Box>
                <Box>
                  <Text
                    as="h3"
                    variant="h3"
                    sx={{ mt: [0], fontSize: [4], color: "light" }}
                  >
                    {subtitle}
                  </Text>
                </Box>
                <Box
                  sx={{
                    width: ["100%", "65%"],
                    p: {
                      fontWeight: "300",
                      lineHeight: "25px",
                      color: "light",
                      fontSize: [1],
                    },
                    opacity: ".8",
                  }}
                >
                  <RichContentStructuredText text={body} />
                </Box>
                <Box sx={{ mt: [3] }}>
                  <OutboundLink
                    sx={{
                      color: "light",
                      textDecoration: "underline",
                    }}
                    href={link.url}
                    target="_blank"
                  >
                    {link.anchor}
                  </OutboundLink>
                </Box>
              </FadeInWhenVisible>
            </Flex>
          </Container>
        </Box>
        <GatsbyImage image={image.gatsbyImageData} alt="" />
      </Box>
    </Box>
  )
}

export default ImageBgAndText
